import { ReactNode } from 'react'

import { cn } from 'utils'
import IconExpand from 'icons/chevron-down-new.svg'

import * as AccordionPrimitive from '../primitive'
import classes from './default.module.css'

type ThemeClassesName = 'item' | 'trigger' | 'content' | 'arrow' | 'itemNumber'

export type ThemeClasses = Partial<Record<ThemeClassesName, string | undefined>>

export interface AccordionNewProps {
  title: ReactNode
  className?: string
  theme?: null
  defaultOpen?: boolean
  classes?: ThemeClasses
  index?: number
}

export const Default: FCC<AccordionNewProps> = ({
  title,
  children,
  className,
  classes: componentClasses,
  defaultOpen,
  index,
}) => {
  const useNumbers = typeof index === 'number'

  return (
    <AccordionPrimitive.AccordionItem
      className={cn(classes.item, componentClasses?.item, className)}
      defaultIsOpen={defaultOpen}
    >
      <AccordionPrimitive.AccordionTrigger
        className={cn(classes.trigger, componentClasses?.trigger)}
      >
        {useNumbers ? (
          <p className={componentClasses.triggerText}>
            <span className={componentClasses.itemNumber}>{index + 1 + '.'}</span>
            {title}
          </p>
        ) : (
          title
        )}
        <IconExpand className={cn(classes.arrow, componentClasses?.arrow)} />
      </AccordionPrimitive.AccordionTrigger>
      <AccordionPrimitive.AccordionContent
        className={cn(classes.content, componentClasses?.content)}
      >
        {children}
      </AccordionPrimitive.AccordionContent>
    </AccordionPrimitive.AccordionItem>
  )
}
