import React, { ReactHTML, ReactNode, useContext, useState } from 'react'
import AnimateHeight from 'react-animate-height'

type ContextState = {
  isOpen: boolean
  setState: React.Dispatch<React.SetStateAction<boolean>>
}

const AccordionContext = React.createContext<ContextState>({
  isOpen: false,
  setState: () => {
    throw new Error('Context Not Found')
  },
})

interface AccordionItemProps {
  children: ReactNode
  className?: string
  defaultIsOpen?: boolean
  as?: keyof ReactHTML
}

const AccordionItem = ({
  as: Component = 'article',
  defaultIsOpen,
  ...props
}: AccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false)

  return (
    <AccordionContext.Provider value={{ isOpen, setState: setIsOpen }}>
      <Component data-open={isOpen} {...props} />
    </AccordionContext.Provider>
  )
}

interface AccordionTriggerProps {
  children: ReactNode
  className?: string
}

const AccordionTrigger = (props: AccordionTriggerProps) => {
  const { isOpen, setState } = useContext(AccordionContext)

  const toggle = () => {
    setState((prev) => !prev)
  }

  return <button aria-expanded={isOpen} onClick={toggle} data-open={isOpen} {...props} />
}

interface AccordionContentProps {
  children: ReactNode
  className?: string
}

const AccordionContent = ({ className, children }: AccordionContentProps) => {
  const { isOpen } = useContext(AccordionContext)

  return (
    <AnimateHeight
      height={isOpen ? 'auto' : 0}
      data-open={isOpen}
      role="region"
      contentClassName={className}
    >
      {children}
    </AnimateHeight>
  )
}

const Item = AccordionItem
const Trigger = AccordionTrigger
const Content = AccordionContent

export { AccordionTrigger, AccordionItem, AccordionContent, Item, Content, Trigger }
