import { FC } from 'react'
import { useInView } from 'react-intersection-observer'

import { Heading } from 'components/heading'
import { Section } from 'components/section'
import { cn } from 'utils'
import { useIsTabletOrMobile } from 'utils/media'

import classes from './AdvantagesList.module.css'
import { AdvantagesItem, AdvantagesItemProps } from './AdvantagesItem'

interface AdvantagesListProps {
  title: string
  description: string
  subtitle?: string
  items: AdvantagesItemProps[]
  className?: string
  classNameList?: string
  classNameListItem?: string
  classNameDescription?: string
  classNameIcon?: string
}

export const AdvantagesList: FC<AdvantagesListProps> = ({
  subtitle,
  title,
  description,
  items,
  className,
  classNameList,
  classNameListItem,
  classNameIcon,
  classNameDescription,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile()

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    skip: isTabletOrMobile,
  })

  return (
    <Section className={cn(classes.container, className)} theme="outerGap">
      {subtitle && (
        <Heading theme="h6" component="h3" className={classes.subTitle}>
          {subtitle}
        </Heading>
      )}
      <Heading theme="h2" className={classes.sectionTitle}>
        {title}
      </Heading>
      <p className={cn(classes.sectionDescription, classNameDescription)}>{description}</p>
      <ul className={cn(classes.list, classNameList)} ref={ref} data-is-animation={inView}>
        {items.map((props, index) => (
          <AdvantagesItem
            {...props}
            key={index}
            index={index}
            trackView={isTabletOrMobile}
            className={cn(classNameListItem, props.className)}
            classNameIcon={cn(classNameIcon, props.classNameIcon)}
          />
        ))}
      </ul>
    </Section>
  )
}
