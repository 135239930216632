import { useRef, useState, FC } from 'react'
import { toast } from 'react-toastify'
import Wistia from 'react-player/wistia'
import WistiaPlayer from 'react-player/wistia'
import { useRouter } from 'next/router'

import { CloseRouteDialogButton, RouterDialog, useControlRouterDialog } from 'components/dialog'
import { DIALOG_NAME } from 'const/dialogs'
import { DialogProps } from 'components/dialog/Dialog'
import { Loader } from 'components/loader'
import { DEFAULT_ERROR_MESSAGE } from 'api/request'
import { ORIGIN_URL } from 'const/config'
import { resolveUrl } from 'utils/url'

import classes from './video-modal.module.css'

export interface VideoModalProps {
  dialogName: DIALOG_NAME
  wistiaId: string
  videoShareTitle: string
  wistiaSubDomain?: string
}

const dialogClasses: DialogProps['classes'] = {
  body: classes.body,
  content: classes.content,
}

export const VideoModal: FC<VideoModalProps> = ({
  dialogName,
  wistiaId,
  videoShareTitle,
  wistiaSubDomain = 'home',
}) => {
  const { asPath } = useRouter()
  const [loading, setLoading] = useState(true)
  const { close } = useControlRouterDialog()
  const player = useRef<WistiaPlayer | null>(null)

  const onError = () => {
    close(dialogName)
    toast.error(DEFAULT_ERROR_MESSAGE)
  }

  return (
    <>
      <RouterDialog modalName={dialogName} withoutHeader classes={dialogClasses}>
        <CloseRouteDialogButton dialogName={dialogName} className={classes.closeBtn} />

        <div className={classes.videoContainer}>
          <Wistia
            ref={player}
            url={`https://${wistiaSubDomain}.wistia.com/medias/${wistiaId}`}
            playing={true}
            controls={true}
            className={classes.iframe}
            width="100%"
            height="100%"
            loop
            config={{
              options: {
                fitStrategy: 'cover',
                playerColor: '#527079', //--color-primary-500
                endVideoBehavior: 'reset',
                plugin: {
                  share: {
                    channels: 'facebook-twitter-linkedIn-email',
                    overrideUrl: 'true',
                    pageUrl: resolveUrl(ORIGIN_URL, asPath),
                    pageTitle: `REZITRADE. ${videoShareTitle}`,
                  },
                },
              },
            }}
            onReady={() => setLoading(false)}
            onError={onError}
          />
        </div>
        {loading && <Loader size="medium" className={classes.loader} />}
      </RouterDialog>
    </>
  )
}
