import { FC } from 'react'

import { Faq } from 'api'
import { cn } from 'utils'
import { Accordion, AccordionThemeClasses } from 'components/accordion'
import { Markdown } from 'components/markdown'

import styles from './faq-list.module.css'

export interface FaqListProps {
  items: Faq[]
  className?: string
}

const ACCORDION_CLASSES: AccordionThemeClasses = {
  item: styles.accordionItem,
  trigger: styles.accordionTrigger,
}

export const FaqList: FC<FaqListProps> = ({ items, className, ...props }) => {
  return (
    <ul {...props} className={cn(styles.list, className)}>
      {items.map((item) => (
        <li className={styles.item} key={item.id}>
          <Accordion title={item.title} classes={ACCORDION_CLASSES}>
            <Markdown className={styles.content}>{item.content}</Markdown>
          </Accordion>
        </li>
      ))}
    </ul>
  )
}
