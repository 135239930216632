import { CSSProperties, FC } from 'react'
import { useInView } from 'react-intersection-observer'

import { Heading } from 'components/heading'
import { cn } from 'utils'

import classes from './AdvantagesItem.module.css'

export interface AdvantagesItemProps {
  icon: FC<any>
  iconColor?: 'primary' | 'secondary' | 'neutral' | 'tertiary' | 'accent' | 'quaternary'
  trackView?: boolean
  title: string
  content: string
  className?: string
  classNameIcon?: string
}

export interface InnerAdvantagesItemProps extends AdvantagesItemProps {
  index: number
}

export const AdvantagesItem: FC<InnerAdvantagesItemProps> = ({
  icon: Icon,
  content,
  title,
  iconColor = 'primary',
  className,
  classNameIcon,
  trackView,
  index,
}) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
    skip: !trackView,
  })

  return (
    <li
      className={cn(classes.listItem, inView && classes.animation, className)}
      style={{ '--transition-delay': `${index * 0.25}s` } as CSSProperties}
      ref={ref}
    >
      <span className={cn(classes.iconContainer, classes[`${iconColor}IconColor`], classNameIcon)}>
        <Icon />
      </span>
      <div>
        <Heading theme="h5" component="h3" className={classes.listItemTitle}>
          {title}
        </Heading>
        <p className={classes.content}>{content}</p>
      </div>
    </li>
  )
}
