import { FC } from 'react'

import { Faq } from 'api'
import { Link } from 'components/links'
import { cn } from 'utils'
import { routes } from 'utils/routes'

import styles from './faq-link.module.css'

export interface FaqLinkProps {
  title: Faq['title']
  slug: Faq['slug']
  className?: string
}
export const FaqLink: FC<FaqLinkProps> = ({ slug, title, className }) => {
  return (
    <Link href={routes.faq(slug)} className={cn(styles.link, className)}>
      {title}
    </Link>
  )
}
