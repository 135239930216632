import { FC } from 'react'

import { Faq } from 'api'
import { Heading } from 'components/heading'
import { OffsetSection } from 'sections/faqs/offset-section'

import { FaqLink } from './faq-link'
import classes from './faq-trending-section.module.css'

export interface FaqTrendingSectionProps {
  items: Faq[]
}
export const FaqTrendingSection: FC<FaqTrendingSectionProps> = ({ items }) => {
  if (!items.length) return null

  return (
    <OffsetSection className={classes.section} classNameInnerContainer={classes.container}>
      <Heading component="h2" theme="h5" className={classes.title}>
        Trending Topics
      </Heading>
      <ul className={classes.list}>
        {items.map(({ id, slug, title }) => (
          <li key={id} className={classes.item}>
            <FaqLink slug={slug} title={title} />
          </li>
        ))}
      </ul>
    </OffsetSection>
  )
}
