import { ReactNode, FC } from 'react'

import { LinkButton } from 'components/links'
import { Section, SectionProps } from 'components/section'
import { Faq } from 'api'
import { routes } from 'utils/routes'
import { Heading } from 'components/heading'
import { cn } from 'utils'

import { FaqList } from './faq-list'
import classes from './faq-section.module.css'

export interface FaqSectionProps extends SectionProps {
  items?: Faq[]
  title: ReactNode
}

export const FaqSection: FC<FaqSectionProps> = ({ items, title, className, ...props }) => {
  if (!items || !items?.length) return null
  return (
    <Section theme="hero" {...props} className={cn(classes.section, className)}>
      <Heading component="h2" theme="h2" className={classes.title}>
        {title}
      </Heading>
      <LinkButton href={routes.faqs} theme="accentLinkToMobileBtn" className={classes.allLink}>
        VIEW ALL FAQs
      </LinkButton>
      <FaqList items={items} className={classes.list} />
    </Section>
  )
}
