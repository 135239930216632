import { FC } from 'react'
import _ from 'lodash'

import { Faq } from 'api'
import { cn } from 'utils'

import classes from './faq-sub-category.module.css'
import { FaqLink } from './faq-link'

interface FaqSubCategoryProps {
  name?: string
  faqs: Faq[]
  className?: string
}

export const FaqSubCategory: FC<FaqSubCategoryProps> = ({ name, faqs, className }) => {
  return (
    <figure className={cn(classes.listContainer, className)}>
      {name && <figcaption className={classes.title}>{name}</figcaption>}

      <ul className={cn(classes.list, !name && classes.listWithoutCategory)}>
        {_.sortBy(faqs, (fs) => fs.title).map((faq) => (
          <li key={faq.id} className={classes.listItem}>
            <FaqLink {...faq} />
          </li>
        ))}
      </ul>
    </figure>
  )
}
