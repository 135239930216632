import { ComponentProps, useState, FC } from 'react'
import axios from 'axios'
import NextImage from 'next/image'
import { useQuery } from '@tanstack/react-query'
import type { SetOptional } from 'type-fest'

import { VideoPlaceholderResponse } from 'components/video/video-placeholder/types'
import { Loader } from 'components/loader'
import DEFAULT_IMAGE from 'components/video/video-placeholder/background.jpg'

import PlayButton from './playButton.svg'
import { QUERY_KEY } from '../../../const/query'
import classes from './video-placeholder.module.css'

export interface WistiaVideoPlaceholderProps {
  wistiaId: string
  placeholderImageOptions?: SetOptional<
    Omit<ComponentProps<typeof NextImage>, 'layout' | 'src'>,
    'alt'
  >
  wistiaSubDomain?: string
}

export const WistiaVideoPlaceholder: FC<WistiaVideoPlaceholderProps> = ({
  wistiaId,
  placeholderImageOptions,
  wistiaSubDomain = 'home',
}) => {
  const [waitingRender, setWaitingRender] = useState(true)
  const {
    data: response,
    error,
    isLoading,
  } = useQuery(
    [QUERY_KEY.videoPlaceholder, wistiaId, wistiaSubDomain],
    () =>
      axios.get<VideoPlaceholderResponse>(
        `https://fast.wistia.net/oembed?url=https://${wistiaSubDomain}.wistia.com/medias/${wistiaId}?videoWidth=2000`,
      ),
    { refetchInterval: false, refetchOnWindowFocus: false, refetchOnMount: false },
  )

  const onLoad = () => {
    setWaitingRender(false)
  }

  const hasNotImage = !response || error || !response?.data?.thumbnail_url

  if (isLoading) {
    return <Loader size="medium" className={classes.loader} />
  }

  return (
    <>
      {hasNotImage ? (
        <>
          <NextImage
            src={DEFAULT_IMAGE}
            placeholder="blur"
            alt="Video placeholder"
            fill
            className={classes.image}
            onLoadingComplete={onLoad}
            {...placeholderImageOptions}
          />
          <PlayButton className={classes.playBtn} />
        </>
      ) : (
        <NextImage
          src={response.data.thumbnail_url}
          alt="Video placeholder"
          fill
          className={classes.image}
          quality={95}
          onLoadingComplete={onLoad}
          priority={false}
          {...placeholderImageOptions}
        />
      )}

      {waitingRender && <Loader size="medium" className={classes.loader} />}
    </>
  )
}
