import { useEffect, useState, FC } from 'react'

import { cn } from 'utils'
import { VideoModal } from 'components/video/video-modal'
import { VideoModalProps } from 'components/video/video-modal/video-modal'
import { useControlRouterDialog } from 'components/dialog'
import { useCallbackOnce } from 'utils/use-callback-once'
import {
  WistiaVideoPlaceholder,
  WistiaVideoPlaceholderProps,
} from 'components/video/video-placeholder/video-placeholder'

import classes from './video.module.css'

interface VideoProps extends VideoModalProps, WistiaVideoPlaceholderProps {
  className?: string
  classNameVideo?: string
}

export const Video: FC<VideoProps> = ({
  className,
  placeholderImageOptions,
  wistiaSubDomain = 'home',
  ...rest
}) => {
  const { open } = useControlRouterDialog()
  const [isFocus, setIsFocus] = useState(false)

  const openVideo = useCallbackOnce(() => {
    open(rest.dialogName)
  })

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        openVideo()
      }
    }

    if (isFocus) {
      window.addEventListener('keydown', enterKeydown)
    } else {
      window.removeEventListener('keydown', enterKeydown)
    }

    return () => {
      window.removeEventListener('keydown', enterKeydown)
    }
  }, [isFocus, openVideo])

  return (
    <section
      className={cn(className, classes.container)}
      onClick={openVideo}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    >
      <span className={classes.decor} />
      <div className={classes.video} tabIndex={0}>
        <WistiaVideoPlaceholder
          wistiaId={rest.wistiaId}
          placeholderImageOptions={placeholderImageOptions}
          wistiaSubDomain={wistiaSubDomain}
        />
      </div>
      <VideoModal wistiaSubDomain={wistiaSubDomain} {...rest} />
    </section>
  )
}
