import { cn } from 'utils'
import { Section } from 'components/section'

import classes from './offset-section.module.css'

interface OffsetSectionProps {
  className?: string
  classNameInnerContainer?: string
}

export const OffsetSection: FCRC<OffsetSectionProps> = ({
  className,
  classNameInnerContainer,
  children,
}) => {
  return (
    <Section className={cn(classes.section, className)}>
      <div className={cn(classes.container, classNameInnerContainer)}>{children}</div>
    </Section>
  )
}
